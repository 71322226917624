<template>
  <div class="about">
    <h1>This is an about page</h1>
    <vue-plyr>
      <audio controls crossorigin playsinline>
        <source
          src="https://museumslauscher.cukero.han-solo.net/assets/audio/audiobeispiel.mp3"
          type="audio/mp3"
        />
<!--        <source-->
<!--          src="/path/to/audio.ogg"-->
<!--          type="audio/ogg"-->
<!--        />-->
      </audio>
    </vue-plyr>
  </div>
</template>

<script>
export default {
  name: 'Component',
  mounted () {
    console.log(this.$refs.plyr.player)
  }
}
</script>
